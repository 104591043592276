import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DynamicImg from "../components/imageDynamic"

const BandMember = ({ children }) => (
  <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>{children}</div>
)

const MemberImg = ({ fileName }) => (
  <div style={{ minWidth: `150px`, maxWidth: `200px`, margin: `0.5rem` }}>
    <DynamicImg src={fileName} />
  </div>
)

const AboutPage = () => (
  <Layout>
    <SEO title="Über uns" />
    <h1>Über uns</h1>

    <h2>Heinrich Arendt, Klarinette und Saxofon</h2>

    <BandMember>
      <MemberImg fileName="HeinrichCircle.png" />
      <p>
        spielte auch in der Bigband "Die Altmeister" und anderen Gruppen. Langjähriges Bandmitglied bis 2018 bei
        BLUESWING (seit 2018 umbenannt in <span style={{ fontWeight: "bold" }}> "jbr five"</span>).
      </p>
    </BandMember>

    <h2 style={{ marginTop: `2rem` }}>Karsten Dau, Klavier</h2>
    <BandMember>


      <p>
        seit der Schulzeit klassischer Klavierunterricht, seit den 60er Jahren in seiner Jugend Interesse am Jazz,
        anfangs mit Mitschülern und Gleichgesinnten aus der Volksdorfer Szene. Hat auch in einer weiteren Jazzband gut
        35 Jahre als Pianist mitgespielt: im Blue Minor Swingtett, das sich 2014 auflöste. Mit Heinrich ebenfalls
        viele Jahre bei BLUESWING.
      </p>

      <MemberImg fileName="KarstenCircle.png" />
    </BandMember>

    <h2 style={{ marginTop: `2rem` }}>Holger Off, Drums</h2>

    <BandMember>
      <MemberImg fileName="HolgerCircle.png" />

      <p>
        1965 erste Versuche in der Klassenband mit Rockmusik. 1968-1971 Auftritte als Trio in Hamburger Jugendhäusern,
        u.a. Flachsland, Stilrichtung Cream, Hendrix. Nach 24 Jahren Pause mit neuer Band die 60`er bis 90`er, meist auf
        privaten Veranstaltungen (Blues, Pop, Rock bis 2002). Neuanfang Oktober 2013 bei der Band BLUESWING, die ihm u.a. mit Jazz und Swing neue Stilrichtungen am
        Set eröffnet.
      </p>
    </BandMember>

    <h2 style={{ marginTop: `2rem` }}>Hans-Peter Pammesberger, Bass</h2>
    <BandMember>
       <p>
        Musiziert seit Anfang der 70er (Gitarren-Duo, ROCK-POP-Cover-Band), seit Mitte der 80er bis heute dem Jazz
        verbunden (Klavier, Saxofon, Bass, Gitarre), 15 Jahre in eigener Band für private Auftritte (Swing, Latin), Ende
        der 90er Blues-Rock-Band (Klavier), seit 2013 Bandmitglied bei BLUESWING als Bassist. Parallel aktiv im High Noon Jazz Quartet
        (Klavier, Gesang) sowie in einem Klassik-Quintett (Tenor-Saxofon).
      </p>
      <MemberImg fileName="HansPeterCircle.png" />
    </BandMember>

    <h2 style={{ marginTop: `2rem` }}>Kai Röpstorff, Gitarre</h2>
    <BandMember>
      <MemberImg fileName="KaiCircle.png" />

      <p>
        Macht professionell Musik, vorwiegend auf der Gitarre. Er musiziert mit vielen bekannten Musikern in allen
        Stilrichtungen (Vorliebe Blues und Rock) und ist des Öfteren bei Studio- Aufnahmen dabei. Heute engagiert er
        sich insbesondere in der sozialen Musikpädagogik. Seit März 2018 u.a. bei
        <span style={{ fontWeight: "bold" }}> "jbr five"</span>.
      </p>
    </BandMember>
  </Layout>
)

export default AboutPage
